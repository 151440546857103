<template>
  <footer id="footer" class="footer">
    <div class="row">
      <div class="col text-center">
        <p class="pl-5 p-3 text-center footer-p">
          Copyright of Zim Digital 2021 | Company Reg : 12975795
        </p>
      </div>
      <div class="col text-right d-none d-sm-block ">
        <div class="pr-5 p-3">
          <a href="mailto:hello@zimdigital.co.uk">
            <font-awesome-icon icon="envelope" size="2x" class="footer-icons" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: "Footer"
};
</script>

<style lang="scss" scoped>
footer {
  background-color: $zim-pink;
  color: $zim-white;
}

.footer-icons {
  color: $zim-white;
}

.footer-p{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>