<template>
  <div>
    <!-- top navbar -->
    <header
      id="topbar-header"
      class="z-index-fixed header-transparent header-absolute-top sticky-reverse"
    >
      <nav
        class="navbar navbar-expand-lg navbar-light navbar-link-white pb-4 pt-4"
      >
        <div class="container-fluid container-nav position-relative">
          <div class="col">
            <div class="pl-5">
              <a
                href="https://www.instagram.com/thisiszim/"
                target="_blank"
                rel="noopener"
              >
                <font-awesome-icon
                  :icon="{ prefix: 'fab', iconName: 'instagram' }"
                  size="2x"
                  class="instagram-nav-icon animate__animated animate__fadeInDown animate__slower"
                />
              </a>
            </div>
          </div>

          <div class="col text-center">
            <router-link to="/">
              <img
                class="img-fluid logo animate__animated animate__fadeIn animate__slower"
                :src="require('@/assets/zim-digital-logo.svg')"
                alt="Zim Digital Logo"
              />
            </router-link>
          </div>

          <div class="col">
            <div class="pr-5">
              <button
                v-b-toggle.sidebar-right
                class="float-right burger-nav-btn"
                name="navMenuBtn"
              >
                <font-awesome-icon
                  icon="bars"
                  size="2x"
                  class="burger-nav-icon animate__animated animate__fadeInDown animate__slower"
                />
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: "TopBar",
  data() {
    return {
      lastScroll: 0
    };
  },
  methods: {
    handleScroll() {
      let stickyReverse = document.querySelector(".sticky-reverse");
      const scrollUp = "scroll-up";
      const scrollDown = "scroll-down";

      if (stickyReverse) {
        const currentScroll = window.pageYOffset;

        if (currentScroll <= 0) {
          stickyReverse.classList.remove(scrollUp);
          return;
        }

        if (
          currentScroll > this.lastScroll &&
          !stickyReverse.classList.contains(scrollDown)
        ) {
          // down
          stickyReverse.classList.remove(scrollUp);
          stickyReverse.classList.add(scrollDown);
        } else if (
          currentScroll < this.lastScroll &&
          stickyReverse.classList.contains(scrollDown)
        ) {
          // up
          stickyReverse.classList.remove(scrollDown);
          stickyReverse.classList.add(scrollUp);
        }

        this.lastScroll = currentScroll;
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
//import bootstrap and bootstrap-vue required varaibles
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";

//import assan template styles
@import "@/styles/assan-styles/_positions.scss";
@import "@/styles/assan-styles/_variables.scss";
@import "@/styles/assan-styles/_navbar.scss";

// can be used by other components adding this class to the header.
// It will override the color from transparent. 
// see Portfolio.vue
.topbar-header-override{
  background-color: $zim-navy !important;
}

.container-nav {
  background-color: transparent !important;
}

.logo {
  max-width: 150px !important;
}

.burger-nav-btn {
  border: none;
  background: none;
}

.burger-nav-icon {
  color: $zim-orange-strong;
}

.instagram-nav-icon {
  color: $zim-orange-strong;
}

@include media-breakpoint-down(sm) {
  .pl-5,
  .px-5 {
    padding-left: 1rem !important;
  }

  .pr-5,
  .px-5 {
    padding-right: 1rem !important;
  }
}
</style>
