export const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "group" */ "../views/Home.vue"),
  },
  {
    path: "/bespoke-software",
    name: "bespokeSoftware",
    component: () =>
      import(
        /* webpackChunkName: "group" */ "../views/BespokeSoftware.vue"
      ),
  },
  {
    path: "/services/web",
    name: "web",
    component: () =>
      import(
        /* webpackChunkName: "group" */ "../views/services/Web.vue"
      ),
  },
  {
    path: "/services/software-development",
    name: "softwareDevelopment",
    component: () =>
      import(
        /* webpackChunkName: "group" */ "../views/services/SoftwareDevelopment.vue"
      ),
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "group" */ "../views/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/portfolio/:brandName",
    name: "portfolio",
    component: () =>
      import(/* webpackChunkName: "group" */ "../views/Portfolio.vue"),
    props: true, //will map brandName to props parameter
    meta: {
      sitemap: {
        slugs: [
          {
            id: 1,
            brandName: "ring-revolution",
          },
          {
            id: 2,
            brandName: "glow-up",
          },
        ],
      },
    },
  },
  {
    path: "*",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "group" */ "../views/NotFound.vue"),
  },
];
