<template>
  <div>
    <TopBar />
    <SidebarNav />
  </div>
</template>

<script>
import TopBar from "./TopBar";
import SidebarNav from "./SidebarNav";

export default {
  name: "NavBar",
  components: {
    TopBar,
    SidebarNav,
  }
};
</script>

