<template>
  <div>
    <div v-show="!isLivePreview">
      <NavBar />
      <transition
        mode="out-in"
        enter-active-class="animate__animated animate__fadeIn animate__fast"
        leave-active-class="animate__animated animate__fadeOut animate__fast"
      >
        <router-view></router-view>
      </transition>
      <cookie-law theme="dark-lime">
        <div slot="message">
          This website uses cookies to ensure you get the best experience on our
          website. For more infomation on why we use cookies, see our
          <router-link to="/privacy-policy#cookie-policy"
            >Cookie Policy.</router-link
          >
        </div>
      </cookie-law>

      <back-to-top text="Back to top" bottom="200px" right="20px">
        <button type="button" class="btn btn-to-top">
          <font-awesome-icon icon="arrow-up" size="2x" />
        </button>
      </back-to-top>

      <Footer v-show="showFooter" />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/Navigation/Navbar.vue";
import Footer from "./components/Footer.vue";
import CookieLaw from "vue-cookie-law";

export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Home",
    // all titles will be injected into this template
    titleTemplate: "%s - Zim Digital",
  },
  components: {
    NavBar,
    Footer,
    CookieLaw,
  },

  data() {
    return {
      showFooter: false,
      isLivePreview: false,
    };
  },
  beforeCreate() {
    //init loader
    // let loader = this.$loading.show({
    //   color: "#E63758", //zim-pink-strong
    //   loader: "dots",
    // });
    // //trigger loader for x seconds.
    // setTimeout(() => {
    //   loader.hide();
    // }, 500);
  },
  mounted() {
    this.delayShowingFooter();

    if (this.isLivePreview) {
      const bodyEl = document.body;
      bodyEl.classList.add("launch");
    }
  },
  created() {},
  methods: {
    delayShowingFooter() {
      setTimeout(() => {
        this.showFooter = true;
      }, 200);
    },
  },
};
</script>

<style lang="scss">
//load fonts
@font-face {
  font-family: "CaviarDreams";
  src: local("CaviarDreams"), url(./fonts/CaviarDreams.ttf) format("truetype");
}

@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"), url(./fonts/Ubuntu-Regular.ttf) format("truetype");
}

.container-fluid {
  background-color: $zim-navy; //set all the background to navy
}

html,
body {
  overflow-x: hidden; // removes horizontal scrollbar
  font-family: "CaviarDreams" !important;
  // scroll-padding-top: 141px; //required for scrolling to top of sections
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "CaviarDreams";
  font-weight: 700 !important;
}

h1 h2 {
  font-weight: bold !important;
}

.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

//cookie theme (override dark lime theme)
.Cookie--dark-lime {
  background-color: $zim-pink !important;

  a {
    color: $zim-text-dark !important;
  }
  :hover {
    color: $zim-white !important;
  }
}

.Cookie__button {
  background: $zim-blue !important;
  border-radius: 25px !important;
}
.Cookie__button:hover {
  background-color: $zim-orange-strong !important;
}

.btn-to-top {
  color: $zim-white !important;
  background-color: $zim-blue !important;
  border-color: $zim-blue !important;
  width: 60px;
  height: 60px;
  border-radius: 50% !important;
}

.launch {
  /* Location of the image */
  background-image: url("./assets/landing-page.jpg");
  /* Background image is centered vertically and horizontally at all times */
  background-position: center center;
  /* Background image doesn't tile */
  background-repeat: no-repeat;
  /* Background image is fixed in the viewport so that it doesn't move when 
     the content's height is greater than the image's height */
  background-attachment: fixed;
  /* This is what makes the background image rescale based
     on the container's size */
  background-size: cover;
  /* Set a background color that will be displayed
     while the background image is loading */
  background-color: #333c46;
}
</style>
