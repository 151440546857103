<template>
  <div>
    <b-sidebar
      ref="sidebar"
      id="sidebar-right"
      @shown="sidebarShown()"
      right
      shadow
      no-header
    >
      <div class="row pt-5 pb-3">
        <div class="col">
          <div class="pr-5">
            <button @click="closeSidebar" class="float-right sidebar-close-btn">
              <font-awesome-icon icon="times" size="3x" />
            </button>
          </div>
        </div>
      </div>

      <nav class="text-center nav-items">
        <ul data-spy="affix" data-offset-top="205">
          <div class="pb-5">
            <li @click="closeSidebar()">
              <router-link to="/#about" v-scroll-to="'#about'"
                ><h2 class="sidebar-first-item sidebar-odd">
                  About
                </h2></router-link
              >
              Behind the brand // Meet the team
            </li>
          </div>
          <div class="pb-5">
            <li @click="closeSidebar()">
              <router-link to="/#services" v-scroll-to="'#services'"
                ><h2 class="sidebar-even">
                  Services
                </h2></router-link
              >
              Learn more about what we can do for you
            </li>
          </div>
          <div class="pb-5">
            <li @click="closeSidebar()">
              <router-link to="/#packages" v-scroll-to="'#packages'"
                ><h2 class="sidebar-odd">
                  Packages
                </h2></router-link
              >
              See what packages we offer
            </li>
          </div>
          <div class="pb-5">
            <li @click="closeSidebar()">
              <router-link to="/#ourWork" v-scroll-to="'#ourWork'"
                ><h2 class="sidebar-even">
                  Our Work
                </h2></router-link
              >
              Take a look at our marvelous creations
            </li>
          </div>
          <div class="pb-5">
            <li @click="closeSidebar()">
              <router-link to="/#contact" v-scroll-to="'#contact'"
                ><h2 class="sidebar-last-item sidebar-odd">
                  Contact
                </h2></router-link
              >
              Get in touch today. Let's make some magic
            </li>
          </div>
        </ul>

        <div class="row mb-5">
          <div
            class="col"
            v-animate-onscroll.repeat="'animate__animated animate__fadeIn'"
          >
            <a href="https://www.instagram.com/thisiszim/" class="pl-2 pr-2">
              <img
                class="img-fluid social-icon mr-1"
                :src="require('@/assets/social-media/instagram.svg')"
                alt="Zim Digital Instagram"
              />
            </a>

            <a href="https://www.facebook.com/thisiszim/" class="pl-2 pr-2">
              <img
                class="img-fluid social-icon m-1"
                :src="require('@/assets/social-media/facebook.svg')"
                alt="Zim Digital Facebook"
              />
            </a>

            <a href="#" class="pl-2 pr-2">
              <img
                class="img-fluid social-icon m-1"
                :src="require('@/assets/social-media/linkedIn.svg')"
                alt="Zim Digital LinkedIn"
              />
            </a>
          </div>
        </div>
      </nav>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: "SidebarNav",
  methods: {
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
    sidebarShown() {
      this.applyNavbarAnimation();
    },
    applyNavbarAnimation() {
      const oddNavItems = document.querySelectorAll(".sidebar-odd");
      oddNavItems.forEach((oddNavItem) => {
        oddNavItem.classList.add("animate__animated", "animate__fadeInLeft");
      });

      const evenNavItems = document.querySelectorAll(".sidebar-even");
      evenNavItems.forEach((evenNavItem) => {
        evenNavItem.classList.add("animate__animated", "animate__fadeInRight");
      });
    },
  },
};
</script>

<style lang="scss">
//sidebar
.b-sidebar.b-sidebar-right {
  width: 50%;
}

// Medium devices (tablets, 768px and down)
@media screen and (max-width: 768px) {
  .b-sidebar.b-sidebar-right {
    width: 75%;
  }
}

// Small devices (landscape phones, 576px)
@media screen and (max-width: 576px) {
  .b-sidebar.b-sidebar-right {
    width: 100%;
  }
}

.b-sidebar > .b-sidebar-body {
  overflow-x: hidden; //remove horizontal scroll on sidebar
  background-color: $zim-navy;
  color: $zim-white;
}

.sidebar-close-btn {
  border: none;
  background: none;
  color: $zim-white;
}

#sidebar-right a:hover {
  color: $zim-pink;
}

.nav-items ul {
  list-style-type: none;
  padding-left: 0;
}

.nav-items h2 {
  color: $zim-blue;
}

.social-sidebar-icon {
  color: $zim-white;
}

.sidebar-first-item,
.sidebar-last-item {
  position: relative;
}

.sidebar-first-item:before {
  content: "";
  position: absolute;
  width: 20%;
  height: 5px;
  background-color: $zim-pink;
  top: 50%;
  left: 0%;
}

.sidebar-last-item:after {
  content: "";
  position: absolute;
  width: 20%;
  height: 5px;
  background-color: $zim-pink;
  top: 50%;
  right: 0%;
}

// Small devices (landscape phones, 576px)
@media screen and (max-width: 576px) {
  .sidebar-first-item:before {
    width: 20%;
  }

  .sidebar-last-item:after {
    width: 20%;
  }
}

.social-icon {
  max-width: 50px !important;
}
</style>
