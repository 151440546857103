import Vue from "vue";
import App from "./App.vue";
import VueMeta from 'vue-meta'
import VueAnalytics from "vue-analytics";
import VueInstagram from "vue-instagram";
import Tawk from 'vue-tawk'
import Vuelidate from "vuelidate";
import VueScrollTo from "vue-scrollto";
import EmailJs from "emailjs-com";
import "animate.css";
import VueAnimateOnScroll from "vue-animate-onscroll";
import BackToTop from 'vue-backtotop'


//Bootstrap Vue
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

//import global shared css
import "./styles/global.scss"

//font awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faTimes,
  faEnvelope,
  faArrowUp,
  faLaptop,
  faCode,
  faFile,
  faBullhorn,
  faMapMarker,
  faPhone
} from "@fortawesome/free-solid-svg-icons";
// issue with brand icons, need to bind differently - https://www.endpoint.com/blog/2018/07/12/vue-fontawesome-facebook-twitter
import {
  faInstagram,
  faLinkedin,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import router from "./router/router";

//vue loading overlay
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

library.add(
  faBars,
  faTimes,
  faInstagram,
  faLinkedin,
  faFacebook,
  faEnvelope,
  faArrowUp,
  faLaptop,
  faCode,
  faFile,
  faBullhorn,
  faMapMarker,
  faPhone
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

//Configure Google Analytics
Vue.use(VueAnalytics, {
  id: "UA-183765469-1", //tracking ID
  router,
  debug: {
    sendHitTask: process.env.NODE_ENV === "production", //don't send events when not production
  },
});

//vue-meta
Vue.use(VueMeta)

// Install BootstrapVue and Icons plugin
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

//Install instagram plugin
Vue.use(VueInstagram);

//Install Tawk.io
Vue.use(Tawk, {
  tawkSrc: 'https://embed.tawk.to/6086c89c62662a09efc23aad/1f477v96d'
})

//Install Vuelidate (Form Validation)
Vue.use(Vuelidate);

//Install EmailJs
Vue.use(EmailJs);

//Install vue-loading-overlay
Vue.use(Loading);

//Install Vue Animate on Scroll
Vue.use(VueAnimateOnScroll);

//Install back-to-top (button on scroll)
Vue.use(BackToTop);

//ScrollTo ... Section, Div, etc.
//https://vue-scrollto.netlify.app/docs
Vue.use(VueScrollTo, {
  offset: -120,
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
